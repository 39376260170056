<template>
  <div>
    <router-view
      :getInstructorsFaq="getInstructorsFaq"
      :getFaqList="getFaqList"
    ></router-view>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters("faq", ["getFaqList", "getCurrentTopic", "getCurrentLabel"]),
    ...mapGetters("instructors", ["getInstructorsFaq"])
  },
  async created() {
    await this.$store.dispatch("instructors/getInstructorsFaq");
    await this.$store.dispatch("faq/getFaqList");
  },

  mounted() {
    // this.$store.dispatch("faq/getLabelList");
  },

  methods: {}
};
</script>

<style lang="scss" scoped>
.el-button {
  outline: none;
}
</style>
